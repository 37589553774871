.kMLsts {
  padding: 8px;
  width: 100%;
  margin: 0px auto;
}

.iVsyrq {
  text-align: center;
  display: inline-block;
  line-height: 38px;
  font-size: 1.25em;
}

.ikblOk {
  background: rgb(60, 60, 60);
  color: rgb(255, 255, 255);
  border-radius: 24px;
  width: 100%;
  display: inline-block;
  padding: 5px;
  font-size: 16px;
  text-align: center;
}

.tdWfM {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  background: rgb(0, 158, 0);
  float: right;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
}

.boptxW {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  border: 0px;
}

.hBSnPp {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  background: rgb(236, 79, 55);
  float: left;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
}

.kXXIGE {
  cursor: pointer;
  display: block;
  margin: 2px;
  margin-top: 0px;
  text-transform: uppercase;
  text-align: center;
  width: 99%;
  padding: 9px;
  border-radius: 10px;
  min-height: 45px;
  background-color: rgb(102, 178, 69);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(112, 112, 112);
  font-size: 1em;
}

.one-product-widget-container {
  position: relative;
  margin-top: 10px;
  margin-bottom: 5px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgb(35, 35, 35);
  border: 1px solid rgb(211, 211, 211);
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 0.1s linear;
  font-family: var(--mitrFont);
  display: inline-block;
  vertical-align: top !important;
  transition: all 500ms ease 0s;
}

.one-product-widget-container:hover {
  box-shadow: 0 0 7px var(--greenColor);
  opacity: 0.9;
}

.one-product-image-container {
  text-align: center !important;
  padding: 10px;
  background: var(--backgroundColor);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.one-product-image {
  max-width: 100%;
  width: auto;
  height: 235px !important;
}

.one-product-info-container {
  margin: 0px 10px;
  padding-top: 10px;
  text-align: center;
}

.product-item-divider {
  height: 1px;
  background: grey;
  margin: 0;
}

.one-product-category-title {
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  width: 100%;
}

.one-product-info-text {
  overflow: hidden;
  /* white-space: nowrap;
    text-overflow: ellipsis; */
  width: 100%;
}

.one-product-store {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.product-add-to-cart {
  font-size: 25px;
  color: var(--blueColor) !important;
  cursor: pointer;
}

.product-add-to-cart:hover {
  color: rgb(109, 186, 248) !important;
}

.product-add-to-cart:active {
  color: rgb(0, 74, 134) !important;
}

.detail-button-group {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 70px;
}

.detail-button-view-bg {
  background: var(--whiteColor);
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  border: none;
  border-radius: 100%;
  padding: 3px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  vertical-align: middle;
  transition: all 500ms ease 0s;
  transform: scale(1);
  fill: #303b41;
  display: inherit;
  text-align: center;
  display: inline-block;
}

.detail-button-view-bg:hover {
  background: var(--greenColor) !important;
  color: var(--whiteColor) !important;
  box-shadow: none;
  border-color: transparent !important;
  fill: var(--whiteColor);
}

.detail-button-delete-bg {
  background: var(--whiteColor);
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  border: none;
  border-radius: 100%;
  padding: 3px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  vertical-align: middle;
  transition: all 500ms ease 0s;
  transform: scale(1);
  fill: #303b41;
  display: inherit;
  text-align: center;
  display: inline-block;
}

.detail-button-delete-bg:hover {
  background: #ec5d09 !important;
  color: var(--whiteColor) !important;
  box-shadow: none;
  border-color: transparent !important;
  fill: var(--whiteColor);
}

.one-product-category-rect {
  overflow: hidden;
  position: relative;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 14px;
  padding-bottom: 4px;
  height: 200px;
  color: rgb(255, 255, 255);
}

.one-product-category-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-color: #313636;
  border-radius: 8px;
}

.one-product-category-text {
  overflow: 'auto';
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 768px) {
  .one-product-item-image {
    min-height: 235px;
  }
}