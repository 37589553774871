
  .cart-pay-basic-unit-add-button{
    border-radius: 7px;
    color: var(--whiteColor);
    background-color: var(--blueColor);
    font-size: 14px;
    padding: 8px 24px;
  }
  .cart-pay-basic-unit-add-button:hover{
    color: #f0fbff;
  }
  .cart-pay-basic-unit-add-button:active{
    color: #f0fbff;
  }