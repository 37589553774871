.contact-us-contact-us-button {
  background-color: var(--whiteColor);
  padding: 10px 30px;
  color: var(--blueColor);
  font-family: mediumFont;
  font-size: 16px;
  border-radius: 50px;
}

.page-category-item > a {
  color: var(--blackColor);
}
.page-category-item {
  margin-top: 3%;
  margin-bottom: 3%;
  font-size: 16px;
}
.page-category-divider {
  background-color: rgb(187, 187, 187);
}

.footer-page-category-item>a{
    color: var(--blackColor);
}
.footer-page-category-item{
    font-size: 14px;
    font-weight: bold;
}

.landing-header1-text {
  font-family: semiBoldFont;
  color: var(--whiteColor);
  font-size: 45px;
  line-height: 1.2;
}
.landing-header2-text {
  font-family: semiBoldFont;
  color: var(--whiteColor);
  font-size: 30px;
  line-height: 1.2;
}

.landing-description1-text {
  color: var(--whiteColor);
  font-size: 16px;
}
.landing-description2-text {
  font-family: mediumFont;
  font-size: 25px;
}
.landing-header3-text {
  font-family: semiBoldFont;
  font-size: 30px;
}
.landing-description3-text {
  font-family: mediumFont;
  font-size: 14px;
}
.landing-info-container {
  border-radius: 30px;
  padding: 3%;
}

.contact-start-widget {
  border-radius: 20px;
  padding: 5%;
  background-color: var(--whiteColor);
}
.contact-start-email-input {
  font-size: 16px;
  height: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 2px solid var(--blueColor);
  max-width: 100%;
}
.contact-start-email-button {
  font-size: 16px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--blueColor);
  color: var(--whiteColor);
  text-align: center;
  padding: 10px 20px;
  width: 150px;
}
.contact-rect {
  text-align: center;
  background: var(--blueColor);
  width: 100%;
  padding: 3% 0%;
}
@media screen and (max-width: 768px) {
  .landing-header1-text {
    font-size: 25px;
  }
  .landing-header2-text {
    font-size: 25px;
  }
  .contact-start-email-input {
    font-size: 14px;
    height: 35px;
    border: 1px solid var(--blueColor);
  }
  .contact-start-email-button {
    font-size: 14px;
    height: 35px;
    padding-top: 6px;
  }
  .contact-rect {
    padding: 8% 0%;
  }
}
