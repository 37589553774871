.ant-modal.payment-confirm-dlg {
    top:30% !important;
}
.ant-modal.payment-confirm-dlg .ant-modal-title {
    font-family: mediumFont;
    font-size: 18px;
}
.ant-modal.payment-confirm-dlg .ant-modal-content{
    border: 2px solid var(--blueColor);
    border-radius: 5px;
}
.ant-modal.payment-confirm-dlg .ant-modal-header {
    padding-top: 30px;
    border-bottom: none;
}
.ant-modal.payment-confirm-dlg .ant-modal-body {
    padding-top: 5px;
}
.ant-modal.payment-confirm-dlg .ant-modal-footer {
    border-top: none;
}
.ant-modal.payment-confirm-dlg .normal-input {
    border-radius: 8px;
    background: var(--backgroundColor);
    padding: 8px 12px;
}
.ant-modal.payment-confirm-dlg .ant-btn {
    height: 40px;
    padding: 8px 32px;
    border-radius: 8px;
}
.ant-modal.payment-confirm-dlg .ant-modal-close {
    display: none;
}
  