.contact-us-page {
  text-align: center;
  margin: auto;
  margin-top: 0px;
  max-width: 700px;
  
  box-shadow: 0px 0px 20px var(--greyShadowColor);
  border-radius: 10px;

  background-color: var(--whiteColor);

  padding: 50px 20px;
}

.contact-us-page-title {
  font-family: mediumFont;
  font-size: 25px;
  text-align: center;
  margin-bottom: 30px;
}

.contact-us-button {
  margin: auto;
  background-color: var(--blueColor);
  padding: 10px 20px;
  border-radius: 5px;
  color: var(--whiteColor);
  width: 300px;
  margin-top: 40px;
}
.contactus-input {
  margin-top: 30px;
  border-radius: 10px;
  padding: 15px !important;
}

.get-in-touch-container {
  text-align: center;
  margin: 100px;
}

.get-in-touch-title {
  font-family: mediumFont;
  font-size: 24px;
}
.get-in-touch-sub-title {
  font-family: semiBoldFont;
  font-size: 16px;
}
.get-in-touch-description {
  font-family: regularFont;
  font-size: 14px;
  margin-top: 10px;
}
.contactus-icon {
  font-size: 18px;
  margin-right: 7px;
  color: var(--blueColor);
}

@media screen and (max-width: 768px) {
  .contact-us-button {
    width: 200px;
  }
  .contactus-input {
    margin-top: 15px;
    border-radius: 5px;
    padding: 5px !important;
  }
  .get-in-touch-container {
    margin: 10px;
    margin-top: 50px;
  }
}
