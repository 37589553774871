.ant-select {
    width: 100%;
    padding: 2px;
    border-radius: 8px;
    /* border: 1px solid var(--blueColor); */
    text-align: left;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
}
