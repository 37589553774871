.temp-top-menu-widget {
  padding: 20px 20px;
  box-shadow: 0px 0px 20px var(--greyShadowColor);
  background-color: var(--whiteColor);
}
.temp-top-menu-item {
  color: var(--blackColor);
  text-align: center;
}
.temp-top-menu-item:hover {
  color: var(--blueColor);
}
@media screen and (max-width: 600px) {
  .temp-top-menu-widget {
    padding: 5px 5px;
  }
}
