.ant-layout-sider-children .logo {
    cursor: pointer;
    margin-left:15px;
    margin-top:25px;
    margin-bottom:25px;
    margin-right:15px;
    width: 70%;
    height: auto;
    text-align: center;
}
.side-collapse {
    z-index: 1;
    top: 30px;
    left: 100%;
    width: 56px;
    height: 56px;
    cursor: pointer;
    border: 8px solid var(--backgroundColor);
    border-radius: 100px;
    margin-left: -28px;
    background: var(--whiteColor);
    line-height: 40px;
    position: absolute;
    text-align: center;
    border-radius: 50px;
}

.custom-sidebar{
    flex: none !important;
    height: 100vh;
    width: 320px !important;
    max-width: 320px !important;
    background-color: var(--whiteColor);
    border-bottom-right-radius: 35px;
    border-top-right-radius: 35px;
    box-shadow: 1vh;
}
.ant-layout-sider-collapsed.custom-sidebar {
    max-width: 142px !important;
    min-width: 142px !important;
    width: 142px !important;
}
.ant-menu.c-menu {
    width: 100% !important;
    padding: 0px 25px 25px;
    font-family: regularFont;
    font-size: 16px;
    color: var(--blackColor);
    opacity: 0.8;
}
.ant-menu.c-menu.ant-menu-inline {
    border-right: 0px;
}
.ant-menu-inline .c-menu-item.ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px);
}
.ant-menu:not(.ant-menu-horizontal) .c-menu-item.ant-menu-item-selected {
    background-color: var(--blueColor);
    padding-left: 24px;
    color: var(--whiteColor);
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .c-menu-item.ant-menu-item::after, .ant-menu-vertical-right .c-menu-item.ant-menu-item::after, .ant-menu-inline .c-menu-item.ant-menu-item::after {
    border-right: 0px;
}
.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .c-menu-item.ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 55px;
    line-height: 55px;
    padding-left: 20px;
    border-radius: 12px;
}
.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-left: 34px !important;
}
.menu-item-icon.ant-menu-item-icon{
    min-width: 22px !important;
    margin-right: 15px !important;
    font-size: 22px !important;
}
.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    min-width: 22px !important;
    margin-right: 15px !important;
    font-size: 22px !important;
}
@media screen and (max-width: 1024px) {
    .custom-sidebar{
        height: 100% !important;
        width: 100% !important;
        max-width: 100% !important;
        background-color: var(--whiteColor);
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        box-shadow: 1vh;
    }
    .side-collapse {
        left:90%;
    }
    .ant-layout .logo {
        width: 50%;
        padding: 25px;
    }
    .ant-drawer-body .logo {
        width: 90%;
        padding: 0px;
    }
    .ant-menu.c-menu {
        width: 100% !important;
        padding: 0px;
        font-family: regularFont;
        font-size: 16px;
        color: var(--blackColor);
    }
}
@media screen and (max-width: 768px) {
    .side-collapse {
        z-index: 1;
        top: 30px;
        right: 0;
        left: 90%;
        width: 56px;
        height: 56px;
        cursor: pointer;
        border: 8px solid var(--backgroundColor);
        border-radius: 100px;
        margin-left: -28px;
        background: var(--whiteColor);
        line-height: 40px;
        position: absolute;
        text-align: center;
        border-radius: 50px;
    }
    .ant-layout .logo {
        width: 50%;
        padding: 25px;
    }
    .ant-drawer-body .logo {
        width: 90%;
        padding: 0px;
    }
    .ant-menu.c-menu {
        width: 100% !important;
        padding: 0px;
        font-family: regularFont;
        font-size: 16px;
        color: var(--blackColor);
    }
}