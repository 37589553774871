.terms-page {
  width: 90%;
  margin: auto;
  margin-top: 100px;
  text-align: justify;
  margin-bottom: 100px;
  /* color: var(--blackColor); */
}

.terms-page-title {
  font-family: mediumFont;
  font-size: 24px;
  text-align: left;
}
.terms-page-description {
  margin-top: 50px;
  font-size: 20px;
}

.terms-card {
  margin-left: 20px;
}
.terms-header {
  font-family: mediumFont;
  font-size: 18px;
}
.terms-body {
  font-family: regularFont;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .terms-page-title {
    font-size: 24px;
  }
  .terms-page-description {
    margin-top: 30px;
    font-size: 18px;
  }
  .terms-header {
    font-family: mediumFont;
    font-size: 17px;
  }
  .terms-body {
    font-family: regularFont;
    font-size: 16px;
  }
}
