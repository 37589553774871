.common-button{
    border-radius: 7px;
    color: var(--whiteColor);
    background-color: var(--blueColor);
    font-size: 14px;
    padding: 8px 42px;
}

@media screen and (max-width: 768px) {
    .common-button{
        border-radius: 7px;
        color: var(--whiteColor);
        background-color: var(--blueColor);
        font-size: 12px;
        padding: 8px 5px;
    }
    
}