.collapsed-content-title {
  font-weight: mediumFont;
  font-size: 16px;
  cursor: pointer;
  background-color: var(--whiteColor);
  padding: 5px 10px;
  padding-top: 10px;
  box-shadow: 0px 0px 10px var(--greyColor);
  border-radius: 10px;
  max-width: 700px;
  margin-bottom: 10px;
}
.collapsed-content-icon {
  font-size: 30px;
  color: var(--greenColor);
}
.collapsed-content-description {
  font-size: 28px;
}
@media screen and (max-width: 768px) {
  .collapsed-content-title {
    font-size: 18px;
  }
  .collapsed-content-icon {
    font-size: 20px;
    color: var(--greenColor);
  }
}
