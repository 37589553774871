
.ant-modal.confirm-modal {
    top: 30%;
}
.ant-modal.confirm-modal .ant-modal-close {
    display: none;
}
.ant-modal.confirm-modal .ant-modal-content {
    border: 1px solid var(--blueColor);
    border-radius: 10px;
}
.ant-modal.confirm-modal .ant-modal-header {
    text-align: center;
    border-bottom: none;
    margin-top: 20px;
}
.ant-modal.confirm-modal .ant-modal-header .ant-modal-title {
    font-family: mediumFont;
    font-size: 20px !important;
}
.ant-modal.confirm-modal .ant-modal-body {
    padding: 5px 70px 40px;
    text-align: center;
}
.ant-modal.confirm-modal .ant-modal-body>p {
    font-family: mediumFont;
    font-size: 18px !important;
}
.ant-modal.confirm-modal .ant-modal-footer {
    border-top: none;
    padding: 5px 0px 30px 0px;
    text-align: center;
}
.ant-modal.confirm-modal .ant-btn {
    padding: 8px 32px;
    width: 120px;
    height: 100%;
    border-radius: 8px;
}
.ant-modal.confirm-modal .ant-btn.ant-btn-primary {
    padding: 8px 45px;
}

.ant-modal-title {
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
}