.mobile-app-item{
    margin-top: 2%;
    margin-bottom: 2%;
}
.mobile-app-icon {
    width: 100%;
    height: auto;
    max-width: 180px;
}
@media screen and (max-width: 768px) {
    .mobile-app-icon {
        width: 100%;
        height: auto;
        max-width: 150px;
    }
}